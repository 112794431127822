


































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionForm from "@/components/forms/AddHouseholdMemberSessionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import Loader from "@/components/Loader.vue";
import openPdf from "@/lib/openPdf";
import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionForm,
    DynamicForm,
    Loader
  }
})
export default class HouseholdMemberPdp extends Vue {
  @Prop()
  public id!: string;
  private developmentPlans: any = null;
  private member: any = null;
  private submissions: any[] | null = null;
  async loadPersonalDevelopments() {
    this.member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
    this.submissions = (
      await this.$service.providers
        .householdMemberSubmissions(this.id)
        .fetchItemsAsync()
    ).items;
    const q = (
      await this.$service.providers.questionnaires.fetchItemsAsync({
        columnFilters: [
          {
            column: "QuestionnaireType",
            value: `'PersonalDevelopment'`,
            op: "Equals"
          }
        ]
      })
    ).items;
    this.developmentPlans = q;
    for (const submission of this.submissions) {
      this.developmentPlans.forEach((x: any) => {
        if (x.id === submission.questionnaireId) {
          let count = 0;
          for (const answers of submission.answers) {
            if (
              answers["tuples"].length > 0 ||
              answers["tuples"].length > 0 ||
              answers["responseBool"] !== null ||
              answers["responseText"] !== null ||
              answers["responseDate"] !== null
            ) {
              count++;
            }
          }
          x.count = count;
        }
      });
    }
  }

  async print() {
    //openPdf(this.$service.downloadPersonalDevelopmentPlan(this.id));
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.downloadPersonalDevelopmentPlan(this.id),
      `${this.member.firstName}_${this.member.lastName}_${utc}_Personal_Development_Plan.pdf`
    );
  }
}
